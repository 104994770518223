
.car-logs {
  padding-top: 2em;
  min-width: 320px;
  min-height: 48px;
  text-align: start;

  .field {
    white-space: nowrap;
    line-height: 22px;

    .label {
      font-weight: 500;
      //margin-bottom: 6px;
    }

    .entry  {
      .date {
        &.empty {
          color: gray;
        }

        &.outdated {
          color: red;
        }
      }

      &.last-entry {
        //color: #008267;
        color: rgb(186, 21, 108);
      }
    }




    .ant-statistic {
      margin-left: 6px;
      display: inline-block;

      .ant-statistic-content {
        .ant-statistic-content-value {
          font-size: 18px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}
