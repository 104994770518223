$error-color: rgb(202, 14, 14);
$error-red: rgba(174, 32, 32, 0.8);
$error-red-darker: rgba(210, 10, 10, 0.7);
$error-red-dark: rgba(224, 51, 51, 0.25);

.view.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-container {
    padding-bottom: 2em;

    .ant-card {
      .ant-card-head {
        border-bottom: 0;
        padding-top: 1em;
      }
      .ant-card-body {
        padding: 1em;
        padding-top: 0.5em;
        width: 300px;

        .ant-input-affix-wrapper,
        .ant-skeleton.ant-skeleton-element {
          &:not(:first-child) {
            margin-top: 1em;
          }


          .ant-input-prefix {
            .anticon {
              color: rgba(0, 0, 0, .25);
            }
          }
        }

      }


      .ant-skeleton {
        width: 100%;

        .ant-skeleton-input {
          width: 100%;
        }
      }


      .login-button-container {
        margin-top: 2em;
        text-align: center;

        .ant-btn,
        .ant-skeleton-button {
          width: 76px;
        }
      }
    }
  }


}

