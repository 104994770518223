@import 'utils/colors';

.access-item {
  padding: 10px 6px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  border-radius: 0.5em;
  background: rgba(241, 241, 245, 0.7);
  line-height: 20px;

  &:nth-child(even) {
    //background: rgba(245, 246, 250, 0.7);
  }


  &:not(:last-child) {
    padding-bottom: 6px;
    margin-bottom: 1em;
    border-bottom: 1px dashed rgba(220, 220, 220, 0.8);
  }

  .access-user-info {
    .phone-number-container {
      margin-bottom: 6px;
      position: relative;
      padding-top: 2px;

      .phone-number {
        position: relative;
        display: inline-block;
        font-size: 22px;
        line-height: 22px;

        font-weight: 500;
        white-space: nowrap;

        &:hover {
          color: $antd-blue;
          cursor: pointer;
        }

        &:active {
          color: $antd-blue-active;
        }

        .tenant-icon {
          cursor: help;
          position: absolute;
          font-size: 18px;
          width: 20px;
          height: 20px;
          line-height: 19px;
          border-radius: 10px;
          text-align: center;
          //background-color: #f7993e;
          background-color: #e69f3d;
          font-weight: 600;
          color: white;
          right: -22px;
          top: -9px;
        }
      }


    }

    .phone-label {
      color: gray;
      font-size: 18px;
      padding: 0.5em 0;
      line-height: 18px;

      &.has-label {
        color: $grayDark;
      }
    }
  }


}

