@import '../../../../utils/colors';

@mixin iconColor($color :rgba(88, 90, 98, 0.7)) {
  .property-icon {
    .icon-container {
      .anticon {
        color: $color;
      }
    }
  }
}

.property-item {
  padding: 10px 0.5em;
  display: flex;
  gap: 1em;
  align-items: center;
  border-radius: 0.5em;
  background: rgba(235, 236, 240, 0.7);
  line-height: 20px;

  .property-info {
    flex: 1 0 auto;
  }

  .property-icon {
    padding: 0.5em;

    .icon-container {
      width: 48px;
      height: 48px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(250, 250, 250, .95);

      .anticon {
        font-size: 28px;
      }
    }
  }



  .property-name {
    font-weight: 500;
    margin-bottom: 0.5em;
  }

  .address {
    color: #676767;
  }

  .square {
    margin-top: 0.5em;
    text-align: end;
    font-size: 14px;
    color: gray;
    font-style: italic;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  &.GARAGE {
    @include iconColor($parkingColor);
  }
  &.FLAT {
    @include iconColor($homeColor);
  }
}
