$areaWidth: 28px;

.access-item-areas {
  //display: inline-flex;
  //align-items: flex-start;
  //justify-content: flex-start;
  width: $areaWidth + 2px;
  text-align: end;
  line-height: 16px;
  min-height: 36px;
  padding-top: 2px;

  &.double-width {
    width: 52px;
  }

  .ant-avatar.ant-avatar-sm {
    text-align: center;
    width: 28px;
    height: 28px;
    font-size: 16px;
  }

  .parking-container {
    .places {
      .place-number {
        color: rgb(100, 100, 100);
        text-align: center;
      }
    }
  }

}

