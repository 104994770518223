.cars-search.view {
  &.centered-content {
    text-align: center;
  }

  .content {
    display: inline-block;
    max-width: 600px;
      max-height: 100%;
      overflow-y: auto;
  }

  //max-width: 600px;

  h4 {
    margin-top: 0;
  }

  .search-form {
    padding-top: 0.5em;
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: center;
  }

  .search-result {
    width: 100%;
    margin-top: 2em;


    span.not-found {
      color: gray;
    }

  }
}
