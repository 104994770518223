
.loadingMask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  //background-color: rgba(255, 255, 255, 0.4);
  background-color: rgb(248 243 234 / 30%);
  align-items: center;
  justify-content: center;
  display: flex;

  :global(.ant-spin-show-text) {
    padding: 10px;
    border-radius: 4px;
    background: white;
  }

  //
  //:global(.ant-spin) {
  //	color: $primaryColor;
  //
  //	:global(.ant-spin-dot) {
  //	:global(.ant-spin-dot-item) {
  //		background-color: $primaryColor;
  //	}
  //	}
  //	//position: absolute;
  //	//top: 45%;
  //	//left: calc(50% - 10px);
  //}

  &.withText {
    .spin {
      //left: calc(50% - 110px);
    }
  }
}
