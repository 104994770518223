@import 'utils/colors';

.access-item-cars {
  margin-top: 0.5em;
  font-size: 18px;

  .car {
    .car-number {
      color: $gray;
    }

    .car-description {
      color: gray;
      margin-left: 8px;
      font-style: italic;
    }
  }
}

