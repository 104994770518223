@import '../../utils/colors';

@mixin iconColor($color : 'inherit') {
  .anticon.ant-menu-item-icon {
    color: $color;
  }
}

.ant-app .ant-layout .ant-layout-footer {
  padding: 1em 1em 2.5em 1em;
  .ant-menu.navigation-menu {
    border-bottom-width: 0;
    background: transparent;
    width: 100%;
    justify-content: space-around;

    .ant-menu-item {
      text-align: center;
      line-height: 20px;

      .anticon.ant-menu-item-icon {
        font-size: 22px;

        &.gate-icon {
          font-size: 24px;
        }
      }

      &::after {
        border-bottom: none;
      }

      .ant-menu-title-content {
        display: block;
        color: inherit;
        margin: 0;
        //padding-top: 2px;
        line-height: 16px;
        font-size: 16px;
      }

      &:not(.ant-menu-item-selected) {
        &:hover {
          @include iconColor($antd-blue-hover);
        }

        &:active {
          @include iconColor($antd-blue-active);
        }

      }

      &.ant-menu-item-selected {
        .anticon.ant-menu-item-icon {
          @include iconColor($antd-blue);
        }
      }

      &:hover {
        &:not(.ant-menu-item-selected) {
          .ant-menu-title-content {
            //color: inherit;
            color: $antd-blue-hover
          }
        }

      }
    }

    &::after,
    &::before{
      display: none;
    }
  }
}

