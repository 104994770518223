body {
  height: 100vh;
}

#root {
  height: 100%;

  .ant-app {
    height: 100%;
    .ant-avatar {
      color: white;
    }

    .ant-layout {
      height: 100%;
      font-size: 16px;

      .ant-layout-content {
        background: rgb(252, 252, 252);

        .view {
          padding: 0.5em 1em 0 1em;
          height: 100%;
          max-height: 100%;
          min-height: 100%;
          text-align: center;
          //overflow-y: auto;

          > h5, > h4 {
            margin-top: 8px;
          }
        }
      }

    }
  }
}

//.app {
//  //font-size: 16px;
//  //text-align: center;
//
//  > .ant-layout {
//    height: 100vh;
//
//    > .ant-layout-content {
//
//      .view {
//        height: 100%;
//        padding: 1em;
//      }
//    }
//  }
//
//
//}
