$copyrightHeight: 40px;

.view.my-profile {
  padding-top: 0.5em !important;

  .profile-content {
    padding: 4px;
    height: calc(100% - $copyrightHeight);
    max-height: calc(100% - $copyrightHeight);
    display: flex;
    flex-flow: column;

    .user-info {
      .ant-avatar {
        width: 64px;
        height: 64px;
        font-size: 32px;
        line-height: 32px;

        .anticon {
          &.god-icon {
            font-size: 38px;
            line-height: 38px;
          }

          &.staff-icon {
            font-size: 38px;
            line-height: 38px;
          }

          &.guard-icon {
            font-size: 36px;
            margin-top: 4px;
            line-height: 36px;
          }
        }
      }

      .user-name {
        h4 {
          margin-top: 8px;
        }
      }
    }

    .user-property {
      text-align: start;
      flex: 1 1 auto;
      //overflow-y: auto;
      min-height: 0;

      .property-list {
        max-height: calc(100% - 60px);
        overflow-y: auto;
      }
    }

    .logout-btn-container {
      //padding: 1em 0;
      text-align: end;
      line-height: 22px;

      .ant-btn {
        font-size: 22px;
        height: 24px;
        width: 24px;
        line-height: 24px;
      }
    }

  }


  .copyright {
    //background: #69b1ff;
    font-size: 14px;
    height: $copyrightHeight;
    line-height: 14px;
    padding: 8px 0;
    color: gray;

    .ant-btn-link {
      padding: 0;
      line-height: 14px;
      font-size: 14px;
    }
  }

}
