.view.granted-accesses {
  //padding: 0.5em 0 0.5em 0 !important;

  .content {
    display: flex;
    flex-flow: column;
    height: 100%;


    h5 {
      margin: 0.5em 0 1em 0;
    }

    > .access-list {
      padding-left: 0.5em;
      padding-right: 0.5em;
      //68px - высота заголовка "Ваши доступы"
      // 60px - высота сносок
      max-height: calc(100% - 68px - 60px);
      text-align: start;
      //flex: 1 1 auto;
      //min-height: 0;
      overflow-y: auto;

      &.empty {
        .empty-placeholder {
          padding: 2em;
        }
      }


    }


  }
}
